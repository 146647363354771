import { useState } from "react";
import { useDispatch } from "react-redux";
import { postRx } from "../../functions/routeFunctions";
import { TextField, Button, CircularProgress } from "@mui/material";

const LoginTab = () => {
  const dispatch = useDispatch();
  const [lock, setLock] = useState(false);
  const [formState, setFormState] = useState({ email: "", password: "" });
  const attemptLogin = () => {
    setLock(true);
    const { email, password } = formState;
    postRx("/login", { email, pwd: password })
      .then(async (r) => {
        setLock(false);
        console.log(r.status);
        if (r.status === 200) {
          const receivedToken = await r.text();
          dispatch({ type: "login", payload: receivedToken });
          dispatch({ type: "changeRoute", payload: "/" });
        } else {
          alert("ERROR " + r.status);
        }
      })
      .catch((e) => {
        setLock(false);
        console.log(e);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 50,
      }}
    >
      {lock ? (
        <CircularProgress />
      ) : (
        <>
          <TextField
            label="Your Email ID"
            style={{ margin: 10, minWidth: 350 }}
            value={formState?.email}
            onChange={(e) =>
              setFormState({ ...formState, email: e.target.value })
            }
          />
          <TextField
            label="Your Password"
            style={{ margin: 10, minWidth: 350 }}
            type="password"
            onChange={(e) =>
              setFormState({ ...formState, password: e.target.value })
            }
          />
          <Button
            variant="contained"
            style={{ margin: 10 }}
            onClick={attemptLogin}
          >
            Login
          </Button>
          <Button style={{ margin: 10 }}>Forgot Password</Button>
        </>
      )}
    </div>
  );
};

export default LoginTab;
