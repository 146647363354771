const IntegrationsTab = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <h1>Integrations</h1>
      </div>
    );
  };
  
  export default IntegrationsTab;
  