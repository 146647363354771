import {
    createTheme
} from "@mui/material";

const myTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(250,73,34)",
    },
    secondary: {
      main: "rgb(0,50,130)",
    },
  },
});

export default myTheme;