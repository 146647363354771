import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  Modal,
  Paper,
  TextField,
} from "@mui/material";

const opx = [{ label: "yo" }];

const ActionModal = ({ isOpen = false, onClose = () => {} }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        style={{
          minWidth: 700,
          minHeight: 400,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <IconButton onClick={() => onClose()} style={{ marginBottom: 10 }}>
          <Close />
        </IconButton>
        <Autocomplete
          disablePortal
          options={opx}
          freeSolo={false}
          renderInput={(params) => <TextField {...params} label="Movie" />}
        />
      </Paper>
    </Modal>
  );
};

export default ActionModal;
