import {
  AddCircleOutline,
  ArchiveOutlined,
  AutoAwesomeMotion,
  CategoryOutlined,
  CheckCircleOutline,
  LocalShipping,
  RemoveCircleOutline,
  StarOutline
} from "@mui/icons-material";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import ActionModal from "./ActionModal/ActionModal";

const iconMap = {
  Stock: <AutoAwesomeMotion />,
  Vendors: <LocalShipping />,
  QC: <CheckCircleOutline />,
};

const iconSeq = ["Stock", "Vendors", "QC"];

const optionMap = {
  Stock: {
    seq: ["Add SKU", "Deactivate SKU", "Add Stock", "Remove Stock"],
    iconMap: {
      "Add SKU": <CategoryOutlined />,
      "Deactivate SKU": <ArchiveOutlined />,
      "Add Stock": <AddCircleOutline />,
      "Remove Stock": <RemoveCircleOutline />,
    },
  },
  Vendors: {
    seq: ["Add Vendor", "Remove Vendor"],
    iconMap: {
      "Add Vendor": <AddCircleOutline />,
      "Remove Vendor": <RemoveCircleOutline />,
    },
  },
  QC: {
    seq: ["Set QC Rating"],
    iconMap: {
      "Set QC Rating": <StarOutline />,
    },
  },
};

const InventoryTab = () => {
  const [mode, setMode] = useState("Stock");
  const [actionModalOpen, setActionModalOpen] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <ActionModal
        isOpen={actionModalOpen}
        onClose={() => setActionModalOpen(false)}
      />
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {iconSeq.map((x) => (
          <Button
            key={x}
            variant="contained"
            startIcon={iconMap[x]}
            style={{ margin: 5, color: x === mode ? "yellow" : "white" }}
            color={x === mode ? "secondary" : "primary"}
            onClick={() => setMode(x)}
          >
            {x}
          </Button>
        ))}
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          marginTop: 10,
        }}
      >
        {optionMap[mode].seq.map((x) => (
          <Button
            variant="contained"
            style={{ margin: 10 }}
            startIcon={optionMap[mode].iconMap[x]}
          >
            {x}
          </Button>
        ))}
      </div>
      <TableContainer
        style={{ width: "90%", marginTop: 10 }}
        component={(e) => <Paper {...e} elevation={3} />}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>SKU ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>SKU Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Quantity (Primary)
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Quantity (Reserved)
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Quantity (Total)
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>QC Fail</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Vendors</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array(12)
              .fill(0)
              .map((_, i) => (
                <TableRow key={i}>
                  <TableCell>SKU{i + 1}</TableCell>
                  <TableCell>ABC{i + 1}</TableCell>
                  <TableCell>5</TableCell>
                  <TableCell>5</TableCell>
                  <TableCell>10</TableCell>
                  <TableCell>2</TableCell>
                  <TableCell>
                    <Button
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      Show
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InventoryTab;
