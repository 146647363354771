import {
  Category,
  DataArray,
  History,
  Home as HomeIcon,
  Inventory,
  JoinFull,
  Login as LoginIcon,
  Logout as LogoutIcon,
  Sell,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const routeDrawerOptionMap = {
  Dashboard: "/",
  Settings: "/settings",
  Logout: "/logout",
  Login: "/login",
  SKUs: "/skus",
  Inventory: "/inventory",
  History: "/history",
  "Data Entry": "/dataentry",
  Integrations: "/integrations",
  Vendors: "/vendors",
};

const routeChecker = (text, currentRoute) =>
  routeDrawerOptionMap[text] === currentRoute ? true : false;

const IconHandler = ({ text = "Dashboard" }) => {
  const currentRoute = useSelector((x) => x?.currentRoute);
  return (
    <>
      {text === "Dashboard" ? (
        <HomeIcon
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Inventory" ? (
        <Inventory
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "SKUs" ? (
        <Category
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "History" ? (
        <History
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Settings" ? (
        <SettingsIcon
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Data Entry" ? (
        <DataArray
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Logout" ? (
        <LogoutIcon
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Vendors" ? (
        <Sell
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Integrations" ? (
        <JoinFull
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
      {text === "Login" ? (
        <LoginIcon
          style={{
            color: routeChecker(text, currentRoute) ? "royalblue" : "black",
          }}
        />
      ) : null}
    </>
  );
};

const DrawerList = ({ onClose = () => {} }) => {
  const loggedIn = useSelector((x) => x.loggedIn);
  const currentRoute = useSelector((x) => x.currentRoute);
  const dispatch = useDispatch();
  const getOptions = (loggedIn) =>
    loggedIn
      ? [
          "Dashboard",
          "Inventory",
          "History",
          "Integrations",
          "Settings",
          "Logout",
        ]
      : ["Dashboard", "Login"];
  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => {}}
      onKeyDown={() => {}}
    >
      <List>
        {getOptions(loggedIn).map((text) => (
          <ListItem
            key={text}
            disablePadding
            onClick={() => {
              dispatch({
                type: "changeRoute",
                payload: routeDrawerOptionMap[text],
              });
              onClose();
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <IconHandler text={text} />
              </ListItemIcon>
              <ListItemText
                primary={text}
                style={{
                  color: routeChecker(text, currentRoute)
                    ? "royalblue"
                    : "black",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const MenuDrawer = ({
  isOpen = false,
  onClose = () => {},
  onOpen = () => {},
}) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <DrawerList onClose={onClose} />
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
