import rootUrl from "../env/rootUrl";

export const postRx = (route, data) =>
  fetch(rootUrl + route, {
    mode: "cors",
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
