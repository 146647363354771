const initialState = {
  loggedIn: false,
  token: null,
  currentRoute: "/",
};

const rootReducer = (state = initialState, action) => {
  if (action.type === "login" && action.payload) {
    return { ...state, loggedIn: true, token: action.payload };
  } else if (action.type === "logout") {
    return initialState;
  } else if (action.type === "changeRoute" && action.payload) {
    return { ...state, currentRoute: action.payload };
  } else return state;
};

export default rootReducer;
