import { Send } from "@mui/icons-material";
import { IconButton, Paper, TextField, Typography } from "@mui/material";

const AIAssistant = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <Paper
        elevation={5}
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Typography variant="h5">
          Talk to our built-in AI assistant! Manage all your inventory without
          lifting a finger.
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            style={{ width: 800, margin: 5 }}
            placeholder="Example: Show me all SKUs with a QC failure rate of more than 25%"
          ></TextField>
          <IconButton
            style={{
              backgroundColor: "gold",
              borderRadius: 10,
            }}
          >
            <Send style={{ color: "white" }} />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <code
            style={{
              backgroundColor: "rgba(0,0,0,0.1)",
              minHeight: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              borderRadius: 10,
            }}
          >
            Hello👋, I'm i2! Your 24x7x365 inventory manager. How can I help you
            today?😊
          </code>
        </div>
      </Paper>
    </div>
  );
};

export default AIAssistant;
