import { AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Topbar({ onMenuClick = () => {} }) {
  const loggedIn = useSelector((x) => x?.loggedIn);
  const dispatch = useDispatch();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <div
              onClick={() =>
                dispatch({ type: "changeRoute", payload: "/login" })
              }
            >
              InventoryDekho
            </div>
          </Typography>
          {loggedIn ? (
            <IconButton
              onClick={() => {
                dispatch({ type: "changeRoute", payload: "/settings" });
              }}
            >
              <AccountCircle style={{ color: "white" }} />
            </IconButton>
          ) : (
            <Button
              color="inherit"
              onClick={() =>
                dispatch({ type: "changeRoute", payload: "/login" })
              }
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
