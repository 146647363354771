import { ThemeProvider } from "@emotion/react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import AIAssistant from "./components/AIAssistant/AIAssistant";
import HistoryTab from "./components/HistoryTab/HistoryTab";
import IntegrationsTab from "./components/IntegrationsTab/IntegrationsTab";
import InventoryTab from "./components/InventoryTab/InventoryTab";
import LoginTab from "./components/LoginTab/LoginTab";
import MenuDrawer from "./components/MenuDrawer/MenuDrawer";
import SettingsTab from "./components/SettingsTab/SettingsTab";
import Topbar from "./components/Topbar/Topbar";
import graphColors from "./constants/graphColors";
import myTheme from "./constants/myTheme";
import { persistor, reduxStore } from "./reduxStore/store";

const sampleGraphData = [
  { name: "SKU A", value: 400 },
  { name: "SKU B", value: 300 },
  { name: "SKU C", value: 200 },
  { name: "SKU D", value: 100 },
];

const SimplePieChart = ({ graphData = [] }) => {
  return (
    <PieChart
      width={300}
      height={300}
      style={{ border: "1px solid grey", borderRadius: 20 }}
    >
      <Pie
        data={graphData}
        cx={150}
        cy={100}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {graphData.map((_, index) => (
          <>
            <Cell
              key={`cell-${index}`}
              fill={graphColors[index % graphColors.length]}
            />
          </>
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

const MetricPieChart = ({ graphLabel = "Primary", graphData = [] }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    }}
  >
    <div style={{ margin: 10 }}>{graphLabel}</div>
    <SimplePieChart graphData={graphData} />
  </div>
);

const DashboardTab = () => {
  const isLoggedIn = useSelector((x) => x.loggedIn);
  const [overallGraphData, setOverallGraphData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!isLoggedIn) {
    }
    setLoading(true);
    fetch("https://jsonplaceholder.typicode.com/todos/1")
      .then(async (response) => {
        const rsp = await response.json();
        setOverallGraphData({ ...rsp, ox1: sampleGraphData });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [isLoggedIn]);
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ margin: 50 }} />
      </div>
    );
  else
    return (
      <>
        <AIAssistant />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MetricPieChart
            graphLabel="Primary"
            graphData={overallGraphData?.ox1}
          />
          <MetricPieChart
            graphLabel="QC Pass"
            graphData={overallGraphData?.ox1}
          />
          <MetricPieChart
            graphLabel="QC Fail"
            graphData={overallGraphData?.ox1}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MetricPieChart
            graphLabel="Packaging"
            graphData={overallGraphData?.ox1}
          />
          <MetricPieChart
            graphLabel="Packaging QC Pass"
            graphData={overallGraphData?.ox1}
          />
          <MetricPieChart
            graphLabel="Packaging QC Fail"
            graphData={overallGraphData?.ox1}
          />
        </div>
      </>
    );
};

const LogoutTab = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "logout" });
  }, [dispatch]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 50,
      }}
    >
      <Typography variant="h6" style={{ marginTop: 50, marginBottom: 10 }}>
        You have logged out. Login again to continue.
      </Typography>
      <Button
        style={{ margin: 10 }}
        variant="contained"
        onClick={() => dispatch({ type: "changeRoute", payload: "login" })}
      >
        Login
      </Button>
    </div>
  );
};

const NotLoggedInTab = () => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" style={{ marginTop: 50, marginBottom: 10 }}>
        You're not logged in. Please login to continue.
      </Typography>
      <Button
        style={{ margin: 10 }}
        variant="contained"
        onClick={() => {
          dispatch({ type: "changeRoute", payload: "/login" });
        }}
      >
        Login
      </Button>
    </div>
  );
};

const RouteHandler = () => {
  const isLoggedIn = useSelector((x) => x.loggedIn);
  const currentRoute = useSelector((x) => x.currentRoute);
  if (currentRoute === "/test") return <div>test route</div>;
  else if (currentRoute === "/login") return <LoginTab />;
  else if (currentRoute === "/logout") return <LogoutTab />;
  else if (currentRoute === "/inventory") return <InventoryTab />;
  else if (currentRoute === "/settings") return <SettingsTab />;
  else if (currentRoute === "/integrations") return <IntegrationsTab />;
  else if (currentRoute === "/history") return <HistoryTab />;
  else if (currentRoute === "/")
    return isLoggedIn ? <DashboardTab /> : <NotLoggedInTab />;
  else return <div>Error 404 : Not Found {currentRoute}</div>;
};

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <ReduxProvider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={myTheme}>
          <div className="App">
            <MenuDrawer
              isOpen={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              onOpen={() => setDrawerOpen(true)}
            />
            <Topbar onMenuClick={() => setDrawerOpen(true)} />
            <RouteHandler />
          </div>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
